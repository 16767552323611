export default function menuToggle() {
	if ($(window).width() < 992) {
		let btnEl = $('.navbar-toggler');
		let menuIcon = '.navbar-toggler .menu-icon';
		const menuHideBox = $('#menuHideBox');
		btnEl.click(function () {
			let menuEl = this.querySelector(menuIcon);
			let openedClassname = menuEl.dataset.toggled;
			setTimeout(() => $(this).attr('disabled', 'true'), 10);
			setTimeout(() => $(this).removeAttr('disabled'), 300);
			if (this.getAttribute('aria-expanded') == 'false') {
				menuHideBox.show();
				menuEl.classList.add(openedClassname);
			} else {
				menuHideBox.hide();
				menuEl.classList.remove(openedClassname);
			}
		})
	}
}